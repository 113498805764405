<template>
  <div>
    <LoginForm @logged-in="onLogin" />
  </div>
</template>

<script>
import LoginForm from '@/components/auth/LoginForm'
import { mapActions } from 'vuex'

export default {
  layout: 'Guest',
  components: {
    LoginForm
  },
  methods: {
    async onLogin () {
      await this.buildPermissionsTable()
      await this.redirect()
    },
    async redirect () {
      const url = this.$router?.history?.current?.query?.redirect || '/'
      await this.$router.push(url)
    },
    ...mapActions({
      buildPermissionsTable: 'user/buildPermissionsTable'
    })
  }
}
</script>
