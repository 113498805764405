<template>
  <v-app id="login" light>
    <v-layout style="margin-top:1em;">
      <v-flex v-if="!splashScreen">
        <v-card
          class="elevation-1 pa-2 mx-auto"
          style="max-width: 400px;"
        >
          <v-card-title>
            <v-img
              src='/logo_small.svg'
              contain
              class="mx-auto"
              :aspect-ratio="16/10"
              alt='logo'
            />
          </v-card-title>
          <v-card-text v-if="oauth">
            <div style="width:100%;">
              <v-form v-model="valid">
                <v-text-field
                  class="pt-1 login-input"
                  label="Identifiant"
                  v-model="email"
                  prepend-icon="mdi-account"
                  :rules="rules.username"
                  required
                  lazy-validation
                  @focus="scrollUp()"
                  @keyup.enter="passFocus()"
                />
                <v-text-field
                  class="pt-1"
                  ref="passwordField"
                  label="Mot de passe"
                  v-model="password"
                  :type="'password'"
                  prepend-icon="mdi-lock"
                  :error-messages="errorMessages"
                  :error="error"
                  @focus="scrollUp()"
                  @keyup.native.enter="submit()"
                />
                <v-btn
                  :outlined="!persist"
                  :color="persist ? 'primary' : 'grey'"
                  class="ml-0 mr-0 pa-1"
                  style="min-width: 32px"
                  @click="persist = !persist"
                >
                  <v-icon v-show="persist">mdi-check-bold</v-icon>
                </v-btn>
                <v-btn
                  text
                  color="grey"
                  @click="persist = !persist"
                  class="ml-0"
                >
                  Se souvenir de moi
                </v-btn>
              </v-form>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-progress-linear
              v-if="loading"
              :indeterminate="true"
            />
            <v-btn
              v-else-if="oauth"
              class="elevation-1"
              :disabled="!password.length || !email.length"
              color="primary"
              light
              block
              @click="submit"
            >
              Se connecter
            </v-btn>
            <v-btn
              v-else
              :href="samlRedirect"
              class="elevation-1"
              color="primary"
              light
              block
            >
              Cliquez ici pour vous connecter
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
      <v-flex v-if="splashScreen" class="mt-1">
        <v-row align="center" justify="center">
          <v-img
            src='/logo_small.svg'
            alt='logo'
            max-height="150"
            contain
          />
        </v-row>
        <div class="text-center text-h2 font-weight-bold mt-9">
          Chargement des données
        </div>
        <v-progress-linear
          v-if="splashScreen"
          :indeterminate="true"
        />
      </v-flex>
    </v-layout>
  </v-app>
</template>

<script>
import { mapActions } from 'vuex'
import { validations, dict } from '@/services/validation'

export default {
  data() {
    return {
      email: '',
      password: '',
      persist: false,
      loading: false,
      error: false,
      errorMessages: [],
      mode: null,
      modes: {
        SAML: 'saml',
        OAUTH: 'oauth'
      },
      valid: false,
      splashScreen: false
    }
  },
  computed: {
    oauth () {
      return this.mode === this.modes.OAUTH
    },
    rules () {
      return validations.login
    },
    samlRedirect() {
      const uri = `?RelayState=${encodeURIComponent(process.env.VUE_APP_URL + '#/login')}`
      let samlIpd = process.env.VUE_APP_SAML_IDP || 'test'
      return process.env.VUE_APP_API_URL + 'saml2/' + samlIpd + '/login' + uri
    }
  },
  async mounted () {
    if (this.$route.params?.authcode?.length > 3) {
      this.mode = this.modes.SAML
      await this.login({ login: this.email, password: this.password })
      this.$emit('logged-in')
    } else if (process.env.VUE_APP_OAUTH_IDP === 'true') {
      this.mode = this.modes.OAUTH
    }
  },
  methods: {
    async submit () {
      const payload = {
        login: this.email.toLowerCase().trim(),
        password: this.password.trim()
      }
      try {
        this.loading = true
        await this.login(payload)
        await this.fetchMe()
        this.splashScreen = true
        this.$emit('logged-in')
      } catch (err) {
        this.$root.$toast({ color: 'error', top:false, bottom:true, right:false, left:true, text:'Erreur' })
        this.error = true
        this.errorMessages = [dict.login.error]
      } finally {
        this.loading = false
        setTimeout(this.reset, 2500)
      }
    },
    reset () {
      this.password = ''
      this.error = false
      this.errorMessages = []
    },
    passFocus() {
      this.$refs.passwordField.focus()
    },
    scrollUp() {
      const el = this.$el.getElementsByClassName("login-input")[0];
      el.scrollIntoView();
    },
    ...mapActions({
      login: 'user/login',
      fetchMe: 'user/fetchMe'
    })
  }
}
</script>
